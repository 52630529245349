<template>
  <div class="w-full max-w-screen-xl relative mx-auto py-8 rounded-lg relative">
    <EditJobFormSteps
      v-if="$usesFeature('candidate-wizard') && job"
      :job="job"
    />
  </div>
</template>

<script>
import EditJobFormSteps from '@components/Jobs/EditJobFormSteps'
import jobsApi from '@api/jobs'

export default {
  components: {
    EditJobFormSteps
  },

  data() {
    return {
      job: null
    }
  },

  created() {
    this.fetchJob()
  },

  methods: {
    /**
     * Fetch the job based on the job id
     */
    fetchJob() {
      this.job = null

      return jobsApi.job(this.$route.params.id)
        .then(job => {
          this.job = job.job
        })
        .catch(error => {
          this.job = null
          throw error
        })
    }
  }
}
</script>
